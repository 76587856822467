<template>
    <v-row>
        <v-col cols="12">
            <v-stepper v-model="step">
                <v-stepper-header>
                    <v-stepper-step step="1" :complete="step > 1">Orçamento</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" :complete="step > 2">Análise de crédito</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3" :complete="step > 3">Contrato</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4" :complete="step > 4">Empréstimo</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="5" :complete="step > 5">Devolução</v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        </v-col>
        <v-col cols="12" md="7" lg="8" order="2" order-md="1">
            <v-card class="mb-5">
                <v-card-title>* Detalhes do pedido</v-card-title>
                <v-card-text>
                    <v-row align="end">
                        <v-col cols="5">
                            <div class="py-0 text-body-1 font-weight-bold text-truncate">Cliente</div>
                            <div class="py-0 text-body-1 font-weight-bold text-truncate">Endereço</div>
                            <div
                                class="py-0 text-body-1 font-weight-bold text-truncate"
                            >Forma de pagamento</div>
                            <div
                                class="py-0 text-body-1 font-weight-bold text-truncate"
                            >Condição de pagamento</div>
                            <div
                                class="py-0 text-body-1 font-weight-bold text-truncate"
                            >Data de início</div>
                            <div class="py-0 text-body-1 font-weight-bold text-truncate">Valor</div>
                        </v-col>
                        <v-col cols="7" class="text-right">
                            <div class="py-0 text-body-1 text-truncate">Fulano da Silva</div>
                            <div
                                class="py-0 text-body-1 text-truncate"
                            >Rua José Inacio, nº 150, Centro - Ivaiporã-PR</div>
                            <div class="py-0 text-body-1 text-truncate">Dinheiro</div>
                            <div class="py-0 text-body-1 text-truncate">à vista</div>
                            <div class="py-0 text-body-1 text-truncate">30/04/2022</div>
                            <div class="py-0 text-body-1 text-truncate">R$ 270,00</div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card>
                <v-card-title>* Lista de equipamentos</v-card-title>
                <v-card-text>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Equipamento</th>
                                <th>Quantidade</th>
                                <th>Prazo</th>
                                <th>Ponto retirada</th>
                                <th>Valor</th>
                                <th>Desconto</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="produtos.length > 0">
                                <tr v-for="(item, i) in produtos" :key="i">
                                    <td class="text-truncate">1010</td>
                                    <td class="text-truncate">#### ##### ######</td>
                                    <td class="text-center text-truncate">###############</td>
                                    <td class="text-truncate">##############</td>
                                    <td class="text-truncate">#########</td>
                                    <td class="text-truncate">R$ ###,##</td>
                                    <td class="text-truncate">R$ ##,##</td>
                                    <td class="text-truncate">R$ ###,##</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td
                                        class="text-center"
                                        colspan="8"
                                    >Nenhum produto adicionado ao pedido</td>
                                </tr>
                            </template>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="5" lg="4" order="1" order-md="2">
            <v-card class="mb-2" v-if="step == 3">
                <v-card-title>* Contrato</v-card-title>
                <v-card-text>
                    <div class="px-3 pb-5">
                        Nesta etapa do processo, clique em imprimir contrato e pegue a assinatura do
                        cliente no mesmo, com o contrato devidamente assinado, tire uma foto dele, anexe no campo
                        abaixo e clique em enviar contrato.
                    </div>
                    <v-file-input class="mb-n7" label="File input" outlined dense></v-file-input>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary lighten-3"
                                class="mb-n3"
                                block
                                elevation="0"
                            >Imprimir contrato</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                block
                                elevation="0"
                                @click="step = 4"
                            >Enviar contrato</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-card class="mb-2" v-if="step == 4">
                <v-card-title>* Renovação</v-card-title>
                <v-card-text>
                    <div class="px-3 pb-5">
                        O pedido se encontra ativo e seus produtos estão emprestados, abaixo você pode solicitar
                        uma renovação dos mesmos.
                    </div>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>
                                    <v-checkbox color="success" />
                                </th>
                                <th>ID</th>
                                <th>Equipamento</th>
                                <th>Prazo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="produtos.length > 0">
                                <tr v-for="(item, i) in produtos" :key="i">
                                    <td>
                                        <v-checkbox color="success" />
                                    </td>
                                    <td class="text-truncate">1010</td>
                                    <td class="text-truncate">#### ##### ######</td>
                                    <td class="text-truncate">##############</td>
                                    <td>
                                        <v-btn fab x-small elevation="0" color="success">
                                            <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </v-simple-table>
                    <v-btn color="success" class="mt-3" block elevation="0">Renovar todos</v-btn>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary lighten-3"
                                class="mb-n3"
                                block
                                elevation="0"
                                @click="step = 3"
                            >Voltar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn
                                color="primary"
                                block
                                elevation="0"
                                @click="step = 5"
                            >Ir para devolução</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
            <v-card class="mb-2" v-if="step == 5">
                <v-card-title>* Devolução</v-card-title>
                <v-card-text>
                    <div class="px-3 pb-5">
                        Nesta etapa você pode confirmar a devolução dos equipamentos alugados pelo
                        cliente, após a devolução ser confirmada o produto não poderá mais ser alterado.
                    </div>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>
                                    <v-checkbox color="red darken-1" />
                                </th>
                                <th>ID</th>
                                <th>Equipamento</th>
                                <th>Prazo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="produtos.length > 0">
                                <tr v-for="(item, i) in produtos" :key="i">
                                    <td>
                                        <v-checkbox color="red darken-1" />
                                    </td>
                                    <td class="text-truncate">1010</td>
                                    <td class="text-truncate">#### ##### ######</td>
                                    <td class="text-truncate">##############</td>
                                    <td>
                                        <v-btn fab x-small elevation="0" color="red darken-1" dark>
                                            <v-icon>mdi-check</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </v-simple-table>
                    <v-btn color="red darken-1" dark class="mt-3" block elevation="0">Devolver todos</v-btn>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary lighten-3"
                                class="mb-n3"
                                block
                                elevation="0"
                                @click="step = 4"
                            >Voltar</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn color="primary" block elevation="0">Finalizar emprestimo</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
    name: "AluguelEquipamentoAcompanhar",
    data: () => ({
        step: 5,
        carregando: false,
        produtos: [],
        dialogRemover: { status: false, produto: {} },
        descontoGeral: "",
        busca: {},
        aguardarBusca: "",
        nomeLimit: 50,
        clientes: [],
        search: null,
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
        items() {
            if (this.clientes == null) return;

            this.clientes.forEach((cliente) => {
                if (cliente.nome.length > this.nomeLimit) {
                    cliente.nome =
                        cliente.nome.slice(0, this.nomeLimit) + "...";
                }
            });

            return this.clientes;
        },
    },
    methods: {
        limparPedido() {
            this.produtos = [];
            localStorage.removeItem("aluguel");
            this.alerta = false;
            this.dialogRemover.status = false;
        },
        removerProdutoPedido(produto) {
            this.produtos.splice(this.produtos.indexOf(produto), 1);
            localStorage.setItem("aluguel", JSON.stringify(this.produtos));
            this.dialogRemover.status = false;
        },
        finalizarPedido() {
            localStorage.removeItem("aluguel");
        },
        carregarClientes(val) {
            clearTimeout(this.aguardarBusca);

            this.aguardarBusca = setTimeout(() => {
                this.carregando = true;
                return axios
                    .post(`${this.backendUrl}cliente/listar`, {
                        limit: 30,
                        offset: 0,
                        busca: val || null,
                    })
                    .then((res) => {
                        this.clientes = res.data.lista;
                        this.carregando = false;
                    })
                    .catch(() => {
                        this.carregando = false;
                    });
            }, this.timeLimit);
        },
    },
    watch: {
        search: function (val) {
            this.carregarClientes(val);
        },
    },
    created() {
        const json = localStorage.getItem("aluguel");
        const produtos = JSON.parse(json);

        if (produtos) {
            this.produtos = produtos;
        }
    },
};
</script>

<style scoped>
</style>